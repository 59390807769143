<template>
  <div class="container pd100">
    <div class="header">
      <!-- <div class="header-block">
        <div class="header-block-item header-block-item--first">
          <div class="header-block-media">
            <van-image class="header-block-avatar" :src="userInfo.avatarUrl" alt="" />
          </div>
          <div class="header-block-title">
            {{ userInfo.nickName }}
          </div>
        </div>
        <div v-for="(item,index) in studentList" :key="index" class="header-block-item" @click="onUserSwitch(item.id)">
          <div class="header-block-media">
            <van-image class="header-block-avatar" :src="item.avatarUrl" alt="" />
          </div>
          <div class="header-block-title">
            {{ item.nickName }}
          </div>
        </div>
      </div> -->
      <!-- <div class="header-setting">
        <van-icon class="header-right-item" class-prefix="icon" size="22" name="SetOutline" @click="onNavigator({path:'/user/profile'})" />
        <van-icon class="header-right-item ml10" class-prefix="icon" size="22" :badge="notice_count" name="BellOutline" @click="onNavigator({path:'/notice'})" />
      </div> -->
    </div>
    <div class="main">
      <div class="group balance-group">
        <van-cell :value="area.label" is-link @click="onArea" />
        <van-cell title="课程顾问" :value="consultant.employee_name" is-link @click="onCourseConsultant" />
        <!-- <div class="group-inner">
          <div v-for="(item,index) in premiumList" :key="index" class="group-item" @click="onNavigator({path:item.route})">
            <div class="balance-group-title">{{ item.value | dataPlaceholder }}</div>
            <div class="balance-group-txt">{{ item.title }}</div>
          </div>
        </div> -->
      </div>

      <!-- <template v-if="listLoading">
        <van-loading class="ui-center" size="24px">加载中...</van-loading>
      </template> -->

      <template>
        <div v-if="list.length" class="cards">
          <div v-for="(item,index) in list" :key="index" class="card" @click="onItemClick(index)">
            <div class="card-hd van-hairline--bottom">
              <div class="card-title van-ellipsis">
                {{ item.fee_title }}
              </div>
              <van-icon name="arrow" :size="16" color="#999" />
            </div>
            <div class="card-bd">
              <!-- <div class="card-bd-item">
                <div class="card-btn">
                  <van-button plain size="small" type="primary">{{ item.fee_type_name }}</van-button>
                </div>
              </div> -->
              <div class="card-bd-item">
                <div class="card-bd-item-title">{{ item.valid_value }}</div>
                <div class="card-bd-item-txt">{{ item.valid_label }}</div>
              </div>
              <div class="card-bd-item">
                <div class="card-bd-item-title">{{ item.give_value }}</div>
                <div class="card-bd-item-txt">{{ item.give_label }}</div>
              </div>
              <div class="card-bd-item">
                <div class="card-bd-item-title">{{ item.amount_value }}</div>
                <div class="card-bd-item-txt">{{ item.amount_label }}</div>
              </div>
            </div>

          </div>
        </div>
        <div v-else class="no-data">
          <van-empty description="课程购买信息~" />
        </div>
      </template>
    </div>
    <!-- 校区选择 -->
    <van-popup v-model="showPicker" position="bottom" round>
      <van-tree-select
        :items="areaList"
        :active-id.sync="areaActiveId"
        :main-active-index.sync="areaActiveIndex"
        @click-item="onAreaClicked"
      />
    </van-popup>
    <van-popup v-model="showConsultantPicker" position="bottom" round>
      <van-picker show-toolbar title="课程顾问" :columns="columns" 
      @cancel="onCancel"
      @confirm="onConfirm"
      @change="onChange" />
    </van-popup>
    <loadings :loading='listLoading' />
  </div>
</template>
<script>
import { Tabbar, TabbarItem, Grid, GridItem, Picker, TreeSelect, Loading,Empty } from 'vant'
import Loadings from '@/components/loading'
import PageMixin from '@/mixins/page'
import {
  saveToken
} from '@/utils/cache'

export default {
  name: 'Premium',
  components: {
    [Empty.name]:Empty,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Picker.name]: Picker,
    [TreeSelect.name]: TreeSelect,
    // [Loading.name]: Loading
    loadings:Loadings,
  },
  filters: {
    formatYuan(value) {
      return parseInt(value).toFixed(2)
    }
  },
  mixins: [PageMixin],
  data() {
    return {
      userInfo: {},
      premiumList: [],
      studentList: [],
      studioList: [],
      notice_count: null, // 消息统计
      // 校区选择
      area: {
        label: '',
        value: ''
      },
      consultant: {
        employee_name: '无',
        course_consultant_id: ''
      },
      columns:[],
      consultant_list:[],
      showPicker: false,
      showConsultantPicker: false,
      areaList: [],
      areaActiveId: '0',
      areaActiveIndex: 0,
      list: [],
      listLoading: true
    }
  },
  created() {
    this.queryUserCenter()
    // this.queryPremiumList()
    this.getStudio()
  },
  methods: {
    onConfirm(value, index) {
      this.consultant = this.consultant_list[index]
      this.showConsultantPicker = false
    },
    onChange(picker, value, index) {
      this.consultant = this.consultant_list[index]
      this.showConsultantPicker = false
    },
    onCancel(){
      this.consultant = {
        employee_name: '无',
        course_consultant_id: ''
      };
      this.showConsultantPicker = false
    },
    queryUserCenter() {
      this.$api.profile_index().then(res => {
        this.notice_count = Number(res.data.notice_count) || null
        this.userInfo = this.initUserInfo(res.data.student)
        if (res.data.student_switch.length) {
          this.studentList = res.data.student_switch.map(item => this.initUserInfo(item))
        }
        this.studioList = res.data.student_belong_studio.map(item => ({ name: item.studio_name, id: item.studio_id }))
        console.log(this.studioList.length)
        if (this.studioList.length) {
          this.area = {
            label: this.studioList[0].name,
            value: this.studioList[0].id
          }
          this.queryPremiumList()
        } else {
          this.listLoading = false
          this.area = {
            label: '--',
            value: '--'
          }
        }
        this.premiumList = res.data.premium.filter(item => item.show === 1)
      })
    },
    onItemClick(index) {
      const { fee_id } = this.list[index]
      const studio_id = this.area.value
      const course_consultant_id = this.consultant.course_consultant_id
      this.$router.push({
        path: `/premium/detail?fee_id=${fee_id}&studio_id=${studio_id}&course_consultant_id=${course_consultant_id}`
      })
    },
    queryPremiumList() {
      this.listLoading = true
      this.$api.premium_index({ studio_id: this.area.value }).then(res => {
        this.listLoading = false
        this.list = res.data.premium.map(item => ({
          ...item,
          valid_label: item.fee_type === 20 || item.fee_type === 30 ? '有效天数' : '可用课时',
          valid_value: item.fee_type === 20 || item.fee_type === 30 ? item.days : item.times,
          give_label: item.fee_type === 20 || item.fee_type === 30 ? '赠送天数' : '赠送课时',
          give_value: item.fee_type === 20 || item.fee_type === 30 ? item.gift_days : item.gift_times,
          amount_label: '收费金额',
          amount_value: item.price
        }))
      }).catch(err=>{
        console.log(err)
      })
      this.$api.premium_consultant({ studio_id: this.area.value }).then(res => {
        this.consultant_list = res.data ;
        this.consultant_list.forEach(item=>{
          this.columns.push(item.employee_name)
        })
      }).catch(err=>{
        console.log(err)
      })
    },
    initUserInfo(item) {
      const userInfo = {
        id: item.student_id,
        nickName: item.student_name || `${item.student_name.student_id}$`,
        avatarUrl: item.student_avatar || `${require('@/assets/avatar_default.png')}`,
        sex: item.student_gender ? String(item.student_gender) : '1',
        birthday: item.student_birthday || '',
        height: item.student_height || '',
        weight: item.student_weight || '',
        health: item.student_health_status || '',
        address: item.student_address || ''
      }
      return userInfo
    },
    getStudio() {
      this.$api.filter_districtstudio().then(res => {
        const data = res.data.studios
        const areaList = []
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            areaList.push({
              text: key,
              children: data[key].map(studio => ({ id: `${studio.studio_id}`, text: studio.studio_name }))
            })
          }
        }
        this.areaList = areaList
        this.areaActiveId = areaList[0].children[0].id
        this.areaActiveIndex = 0
      })
    },
    onArea() {
      this.showPicker = !this.showPicker
    },
    onCourseConsultant() {
      this.showConsultantPicker = !this.showConsultantPicker
    },
    onAreaClicked(data) {
      this.area.label = data.text
      this.area.value = data.id
      this.onArea()
      this.queryPremiumList()
    },
    onSettingClick() {
      this.$router.push({ path: '/setting' })
    }
  }
}
</script>
<style lang="less" scoped>

  .style-box() {
    box-sizing: border-box;
    border-radius: 8px;
    overflow: hidden;
    background: #fff;
  }

  .header {
    box-sizing: border-box;
    width: 100%;
    height: 277px;
    color: #fff;
    padding: 0 15px;
    // background-color: #fe4140;
    padding-top: 70px;
    background: @linear-gradient-color;
    background-image: url('~@/assets/user_bg.png');
    background-size: 100% 100%;
    &-media {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;
    }
    &-title {
      font-size: 18px;
    }
    &-setting {
      position: absolute;
      top: 30px;
      right: 30px;
      font-size: 24px;
    }
  }

  .header-block{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    &-item{
      position: relative;
      margin-right: 10px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      background: #fff;
      flex-shrink: 0;
    }
    &-item--first{
      // position: relative;
      // margin-right: 10px;
      // width: 80px;
      // height: 80px;
      // border-radius: 50%;
      // overflow: hidden;
      // background: #fff;
    }
    &-media {
      width: 100%;
      height: 100%;
    }
    &-title {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      text-align: center;
      font-size: 10px;
      line-height: 1.8;
      color: #fff;
      background: rgba(0,0,0,.3);
      overflow:hidden;
      text-overflow:ellipsis;
      white-space:nowrap;
      word-wrap:normal
    }
  }
  .main {
    // padding:0 20px;
    margin-top: -210px;
  }
  .group {
    .style-box();
    margin: 0 15px 15px 15px;
    &-inner{
      padding:10px 0;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
    }
    &-item{
      flex: 1;
      text-align: center;
    }
  }
  .balance-group {
    .style-box();
    .van-row {
      text-align: center;
    }
    .van-col {
      text-align: center;
    }
    &-title {
      font-size: 14px;
      font-weight: bold;
      color: #000;
      margin-bottom: 5px;
    }
    &-txt {
      font-size: 12px;
      color: #999;
    }
  }
  .changy-group {
    /deep/ .van-grid-item__icon{
      position: relative;
      font-size: 20px;
    }
    // .van-icon {
    //   font-size: 20px;
    // }
    // .van-col {
    //   margin-bottom:10px;
    // }
  }

  .user-gruop {
    &-name {
      // padding: 10px 0;
      color: #333;
      font-size: 12px;
      margin-bottom: 10px;
    }
  }
  .user-section {
    .style-box();
    margin: 0 20px 15px 20px;
    &-title {
      font-size: 14px;
      color: #000;
      font-weight: bold;
    }
    &-hd {
      box-sizing: border-box;
      width: 100%;
      padding: 10px 0px;
      overflow: hidden;
      color: #323233;
      font-size: 14px;
      line-height: 24px;
      background-color: #fff;
      display: flex;
      align-items: center;
    }
    &-bd {
      box-sizing: border-box;
      width: 100%;
      padding: 15px 0;
    }
    &-grid {
      box-sizing: border-box;
      width: 20%;
      float: left;
      text-align: center;
      .van-icon {
        font-size: 24px;
        margin-bottom: 5px;
      }
      &-img {
        display: inline-block;
        width: 30px;
        height: 30px;
        // margin-bottom: 5px;
      }
      &-title {
        color: #333;
        font-size: 12px;
      }
    }
  }

  .card{
    .style-box();
    margin: 0 15px 15px 15px;
    &-hd{
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      font-size: 14px;

    }
    &-title{
      max-width: 80%;
    }
    &-bd{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &-item{
        text-align: center;
        flex: 1;
        padding: 10px 0;
        &-title{
          font-size: 14px;
          font-weight: bold;
          color: #333;
          margin-bottom: 10px;
        }
        &-txt{
          font-size: 12px;
          color: #666;
        }
      }
    }
  }
</style>
